.export_inStorage_modal {
  // .ant-modal-content {
  //   padding: 0;
  //   .ant-modal-close-x {
  //     width: auto;
  //   }
  // }
  // .ant-modal-body {
  //   padding: 12px 24px;
  // }
  .exportType {
    width: 100%;
    height: 30px;
    // border-bottom: 1px solid #e9e9e9;
    margin-bottom: 10px;
  }
}
